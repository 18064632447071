<template>
	<nav
		v-click-outside="vcoMobileConfig"
		class="navbar is-primary has-shadow is-fixed-top"
		role="navigation"
		aria-label="main navigation"
	>
		<div class="navbar-brand">
			<router-link class="navbar-item" to="/" exact>
				<figure class="image is-32x32">
					<img src="/images/menu-logo.svg" alt="Home" />
				</figure>
			</router-link>
			<a
				role="button"
				class="navbar-burger burger"
				:class="{ 'is-active': mobileMenuOpen }"
				aria-label="menu"
				aria-expanded="false"
				data-target="main-navbar"
				@click="mobileMenuOpen = !mobileMenuOpen"
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		<div id="main-navbar" class="navbar-menu" :class="{ 'is-active': mobileMenuOpen }">
			<div class="navbar-start">
				<div class="navbar-item has-dropdown is-hoverable">
					<div class="navbar-item">CMS</div>
					<div class="navbar-dropdown">
						<router-link class="navbar-item" :to="{ name: 'books.index' }"
							>Books</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'assignments.index' }"
							>Assignments</router-link
						>
						<router-link
							class="navbar-item"
							:to="{ name: 'screeningassignments.index' }"
							>Screenings</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'media.index' }"
							>Media</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'taxonomy.index' }"
							>Taxonomy</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'essentials.index' }"
							>Essentials</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'articles.index' }"
							>Articles</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'tooltips.index' }"
							>Tooltips</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'references.index' }"
							>References</router-link
						>
						<router-link
							class="navbar-item"
							:to="{ name: 'sectionrelations.index' }"
						>
							Section relations</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'achievements.index' }">
							Achievements
						</router-link>
						<router-link
							class="navbar-item"
							:to="{ name: 'featuredachievements.index' }"
						>
							Featured achievements
						</router-link>
					</div>
				</div>
				<div class="navbar-item has-dropdown is-hoverable">
					<div class="navbar-item">CRM</div>
					<div class="navbar-dropdown">
						<router-link class="navbar-item" :to="{ name: 'users.index' }"
							>Users</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'schools.index' }"
							>Schools</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'bundles.trials' }"
							>Trials</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'insights.index' }"
							>Insights</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'email.index' }"
							>Emails</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'export.index' }"
							>Teacher Export</router-link
						>
					</div>
				</div>

				<div class="navbar-item has-dropdown is-hoverable">
					<div class="navbar-item">Lix</div>
					<div class="navbar-dropdown">
						<router-link class="navbar-item" :to="{ name: 'dictionary.search' }"
							>Search</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'dictionary.feedback' }"
							>Feedback</router-link
						>
						<router-link
							class="navbar-item"
							:to="{ name: 'dictionary.search-history' }"
							>Search History</router-link
						>
						<router-link
							class="navbar-item"
							:to="{ name: 'dictionary.search-terms-by-frequency' }"
							>Frequent search terms</router-link
						>
					</div>
				</div>

				<div class="navbar-item has-dropdown is-hoverable">
					<div class="navbar-item">System</div>
					<div class="navbar-dropdown">
						<router-link class="navbar-item" :to="{ name: 'notifications.index' }"
							>Notifications</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'news.index' }"
							>News</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'authors.index' }"
							>Authors</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'courses.index' }"
							>Courses</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'assignmenttypes.index' }"
							>Assignment types</router-link
						>
						<router-link
							class="navbar-item"
							:to="{ name: 'screeningassignmenttypes.index' }"
							>Screening types</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'schoolmerger' }"
							>SchoolMerger</router-link
						>
						<router-link class="navbar-item" :to="{ name: 'systemstats' }"
							>Stats dashboard</router-link
						>
					</div>
				</div>
			</div>

			<div class="navbar-end">
				<div class="navbar-item has-dropdown is-hoverable">
					<a class="navbar-link">{{ user ? user.name : '' }}</a>
					<div class="navbar-dropdown">
						<router-link class="navbar-item" :to="{ name: 'profile' }">
							Profile
						</router-link>
						<a class="navbar-item" @click.prevent="logout" @click="logout"
							>Log out</a
						>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Header',

	data() {
		return {
			mobileMenuOpen: false,
			vcoMobileConfig: {
				handler: () => (this.mobileMenuOpen = false),
				middleware: () => this.mobileMenuOpen,
			},
		};
	},

	computed: {
		...mapGetters('user', ['user']),
	},

	watch: {
		$route() {
			this.mobileMenuOpen = false;
		},
	},

	methods: {
		logout() {
			this.$store
				.dispatch('auth/logout')
				.finally(() => this.$router.push({ name: 'login' }))
				.catch(() => {});
		},
	},
};
</script>
